<template>
  <section id="contact">
    <div class="title-row row margin-top-1">
      <span class="title">CONTATOS PRESTADORAS</span>
    </div>
    <div class="leading-row row margin-top-1 justify-center">
      <span class="leading col-xs-12 col-sm-12 col-md-11 col-lg-8">
        Caso não tenha encontrado a resposta para a sua dúvida no
        <a href="#faq">F.A.Q.</a>, você poderá tirar suas dúvidas diretamente
        com a Prestadora:
      </span>
    </div>
    <div class="contacts-row row margin-top-2 justify-center">
      <div class="contacts">
        <a
          :href="item.CONTATO_URL"
          target="_blank"
          v-for="item in results.rows"
          :key="item.OPERADORA"
        >
          <div class="contact-card">
            <label class="phone-number">{{ item.CONTATO_FONE }}</label>
            <label class="carrier">{{ item.OPERADORA }}</label>
          </div>
        </a>
      </div>
    </div>
    <div class="row margin-top-1 justify-center" v-if="errors">
      <div class="col-xs-10 col-md-9">
        <div class="alert alert-danger" role="alert">
          <button
            type="button"
            class="close"
            @click="errors = null"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {{ errors }}
        </div>
      </div>
    </div>
    <div class="hint-row margin-top-1 justify-center">
      <div class="contacts-hint col-md-9">
        <span>CLIQUE NO CARD PARA ACESSAR O SITE DA PRESTADORA.</span>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable arrow-parens */
/* eslint-disable operator-linebreak */
/* eslint-disable operator-linebreak */
import settings from "@/settings";

export default {
  name: "Contato",
  props: {
    msg: String,
  },
  data() {
    return {
      results: [],
      errors: null,
      isLoading: false,
    };
  },
  created() {
    this.isLoading = true;
    const apiUrl = settings.API_URL + "/operadoras";
    fetch(apiUrl, { headers: { "Content-type": "application/json" } })
      .then((res) => res.json())
      .then((data) => {
        console.log("contacts data: ", data);

        if (!data.success) {
          this.errors = "Falha ao consultar Contato Prestadoras.";
        } else {
          this.errors = null;
          this.results = data;
        }
      })
      .catch((error) => {
        this.errors =
          "Não foi possível acessar o servidor, " +
          "verifique sua conexão com a internet";
        console.error(error);
      })
      .finally((result) => {
        console.log(result);
        this.isLoading = false;
      });
  },
};
</script>

<style lang="scss">
#contact {
  padding: 4rem 24vw;
  background-color: #eeeeed;
  text-align: center;
  justify-content: center;

  .title-row {
    .title {
      font-size: 1.8rem;
      font-weight: 500;
      letter-spacing: 1px;
    }
  }

  .leading-row {
    .leading {
      font-size: 1.1rem;
      color: #5e5e5e;
    }
  }

  .contacts-row {
    .contacts {
      display: flex;
      gap: 15px;
      justify-content: center;

      a {
        text-decoration: none;
      }

      .contact-card {
        background-color: #6087bc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        height: 110px;
        width: 90px;

        .phone-number {
          font-size: 1.3rem;
          color: #fff;
          margin: 0;
          font-weight: bold;
          cursor: pointer;
        }

        .carrier {
          font-size: 1.1rem;
          color: #fff;
          margin: 0;
          font-weight: 400;
          cursor: pointer;
        }

        @media screen and (max-width: 767px) {
          height: 100px;
          width: 84px;

          .phone-number {
            font-size: 1.6rem;
          }

          .carrier {
            font-size: 1.1rem;
          }
        }

        @media screen and (max-width: 321px) {
          height: 94px;
          width: 80px;

          .phone-number {
            font-size: 1.5rem;
          }

          .carrier {
            font-size: 1rem;
          }
        }

        @media screen and (min-width: 768px) {
          height: 110px;
          width: 90px;

          .phone-number {
            font-size: 1.2rem;
          }

          .carrier {
            font-size: 1rem;
          }
        }

        @media screen and (min-width: 1024px) {
          height: 120px;
          width: 100px;

          .phone-number {
            font-size: 1.3rem;
          }
        }
      }

      .contact-card:hover {
        background-color: #1f5990;
      }

      @media screen and (max-width: 767px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  .hint-row {
    .contacts-hint {
      color: #6087bc;
      font-weight: bold;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 2rem 10vw;
  }
}
</style>
