<template>
  <div class="layout">
    <Navigation :isFixedNavBar="this.fixedNav" />
    <About />
    <Consultation ref="consultationRef" />
    <Faq />
    <Contact />
  </div>
</template>
<script>
// import debounce from "lodash/debounce";

import Navigation from "./Navigation.vue";
import About from "./About.vue";
import Consultation from "./Consultation.vue";
import Faq from "./Faq.vue";
import Contact from "./Contact.vue";

export default {
  name: "Layout",
  components: {
    Navigation,
    About,
    Consultation,
    Faq,
    Contact,
  },
  data() {
    return {
      fixedNav: false,
    };
  },
  created() {
    // this.handleDebouncedScroll = debounce(this.handleScroll, 50);
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleDebouncedScroll);
  },
  mounted() {
    this.fixedNav = this.$refs.consultationRef.$el.getBoundingClientRect().top <= 0;
  },
  methods: {
    handleScroll() {
      this.fixedNav = this.$refs.consultationRef.$el.getBoundingClientRect().top <= 0;
    },
  },
};
</script>
