import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
// import VueRouter from 'vue-router';
import router from './router';
import App from './App.vue';


Vue.config.productionTip = false;

// Vue.use(VueRouter);
Vue.use(VueTheMask);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
