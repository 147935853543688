<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="modalTermos"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalTermos"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Termos e Condições de Uso e Política de Privacidade
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </h4>
          </div>
          <div class="modal-body">
            <h5>TERMOS E CONDIÇÕES DE USO DO WEBSITE WWW.CADASTROPRE.COM.BR</h5>
            <br />
            <h5>POR FAVOR, LEIA CUIDADOSAMENTE</h5>
            <p>
              Estes Termos e Condições de Uso regulam o uso do serviço Consulta CPF (Cadastro
              Pré-Pago) que será acessado por meio do website www.cadastropre.com.br, mantido pelas
              Prestadoras de Serviços de Telecomunicações participantes e operado pela ABR TELECOM:
            </p>
            <table style="width: -webkit-fill-available; margin: 2rem">
              <thead>
                <th>Empresas Participantes</th>
                <th>Website da Prestadora</th>
              </thead>
              <tbody>
                <tr>
                  <td>GRUPO ALGAR TELECOM</td>
                  <td>
                    <a href="https://www.algartelecom.com.br" target="_blank"
                      >www.algartelecom.com.br</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>GRUPO CLARO</td>
                  <td>
                    <a href="https://www.claro.com.br" target="_blank">www.claro.com.br</a>
                  </td>
                </tr>
                <tr>
                  <td>GRUPO SERCOMTEL</td>
                  <td>
                    <a href="https://www.sercomtel.com.br" target="_blank">www.sercomtel.com.br</a>
                  </td>
                </tr>
                <tr>
                  <td>GRUPO VIVO</td>
                  <td>
                    <a href="https://www.vivo.com.br" target="_blank">www.vivo.com.br</a>
                  </td>
                </tr>
                <tr>
                  <td>GRUPO TIM</td>
                  <td>
                    <a href="https://www.tim.com.br" target="_blank">www.tim.com.br</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Qualquer pessoa inscrita no Cadastro de Pessoa Física do Ministério da Fazenda
              (CPF/MF), doravante denominada USUÁRIO, que pretenda efetuar a consulta do serviço
              oferecido por meio do website www.cadastropre.com.br, se responsabiliza pela leitura
              do presente Termos e Condições de Uso e todas as demais políticas e princípios que as
              regem. Caso você não concorde com qualquer dos termos e condições abaixo
              estabelecidos, você não deve utilizar o website e os serviços aqui descritos.
            </p>
            <p>
              É de responsabilidade do USUÁRIO ler e certificar-se de haver entendido todas as
              disposições estabelecidas nos Termos e Condições de Uso, bem como na Política de
              Privacidade.
            </p>
            <h5>DO USUÁRIO</h5>
            <p>
              O serviço oferecido por meio do website www.cadastropre.com.br, mantido pelas
              Prestadoras de Serviços de Telecomunicações participantes e operado pela ABR TELECOM,
              está disponível para qualquer pessoa inscrita no Cadastro de Pessoa Física do
              Ministério da Fazenda (CPF/MF).
            </p>
            <h5>DESCRIÇÃO DO SERVIÇO</h5>
            <p>
              O website www.cadastropre.com.br é um canal de fácil acesso e uso e visa fornecer ao
              USUÁRIO a possibilidade de realizar uma consulta, de forma rápida e segura, sobre
              quais Prestadoras de Serviços de Telecomunicações participantes possuem linhas
              pré-pagas ativas no CPF consultado.
            </p>
            <p>
              As funcionalidades disponibilizadas na interface do Website para o USUÁRIO são:
              <strong>Consulta às Prestadoras com código de acesso Pré-Pago ativo.</strong>
              Para acessar o resultado da Consulta o USUÁRIO apenas fornecerá o
              <strong>CPF.</strong>
            </p>
            <p>
              Caso o USUÁRIO não reconheça o cadastro de linha Pré-Paga no CPF consultado, será de
              inteira responsabilidade do titular do código de acesso, ou seja, do titular do CPF
              consultado, entrar em contato diretamente com a Prestadora indicada na consulta, para
              solicitar o seu devido cancelamento.
            </p>
            <h5>QUAIS INFORMAÇÕES SÃO TRATADAS</h5>
            <p>
              O dado abaixo é coletado quando inserido voluntariamente pelo USUÁRIO ao realizar a
              consulta no site www.cadastropre.com.br:
            </p>
            <ul style="text-align: start">
              <li>
                CPF
                <p>
                  Informações que coletamos a partir da navegação do USUÁRIO no site
                  www.cadastropre.com.br:
                </p>
              </li>
              <li>logs</li>
              <li>cookies</li>
            </ul>

            <h5>COMO SERÃO UTILIZADAS AS INFORMAÇÕES</h5>
            <p>
              Podemos utilizar os dados que foram coletados e os registros das atividades no site
              www.cadastropre.com.br, para as seguintes finalidades:
            </p>
            <ul style="text-align: start; margin-bottom: 35px">
              <li>Execução da consulta solicitada pelo USUÁRIO;</li>
              <li>Aperfeiçoamento dos serviços oferecidos no website;</li>
              <li>Elaborar análise estatística de segurança e combate à fraude.</li>
            </ul>

            <h5>DIREITO À PRIVACIDADE</h5>
            <p>
              As práticas adotadas em relação aos dados coletados por meio do website
              www.cadastropre.com.br e a forma como protegemos a sua privacidade estão descritas na
              <a target="_blank" href="https://www.abrtelecom.com.br/pagina/politicas"
                >Política de Privacidade</a
              >. Ao utilizar o serviço via website www.cadastropre.com.br, o USUÁRIO toma ciência
              que todas as informações serão tratadas em conformidade com estes Termos e Condições
              de Uso, com a respectiva
              <a target="_blank" href="https://www.abrtelecom.com.br/pagina/politicas"
                >Política de Privacidade</a
              >, bem como de acordo com a legislação vigente.
            </p>
            <p>
              A informação fornecida pelo USUÁRIO será tratada exclusivamente para a finalidade
              informada no presente Termo e na
              <a target="_blank" href="https://www.abrtelecom.com.br/pagina/politicas"
                >Política de Privacidade</a
              >, sendo observada integralmente a legislação vigente sobre a proteção e privacidade
              de dados, sobretudo, mas não exclusivamente, a Lei 13.709/2018.
            </p>

            <h5>LIMITAÇÃO DE RESPONSABILIDADE</h5>
            <p>
              A ABR TELECOM é responsável pelo funcionamento e operação do serviço do website
              www.cadastropre.com.br em nível de capacidade e cuidado.
            </p>
            <p>
              O USUÁRIO é responsável por garantir, de forma independente, a verdade e precisão da
              informação que fornece.
            </p>

            <h5>PROPRIEDADE INTELECTUAL</h5>
            <p>
              O website www.cadastropre.com.br, suas páginas, as telas que apresentem as páginas, as
              informações e os materiais neles contidos e composição, salvo se indicado de outra
              forma, são de propriedade da ABR TELECOM. Todos os direitos são protegidos por
              direitos autorais, marcas registradas, legislação específica e demais leis do Brasil.
              Você concorda em observar todas as leis aplicáveis, assim como quaisquer notificações
              ou restrições adicionais contidas no website.
            </p>
            <p>
              Nada contido nesse site deve ser entendido como uma forma de conceder licença ou
              direito de usar qualquer marca registrada sem a autorização prévia da ABR TELECOM.
            </p>

            <h5>ALTERAÇÕES</h5>
            <p>
              Reservamo-nos no direito de modificar ou alterar o conteúdo deste Termos e Condições
              de Uso a qualquer momento. As alterações destes Termos e Condições serão
              disponibilizadas no website www.cadastropre.com.br e serão válidas a partir da data de
              sua publicação.
            </p>

            <h5>JURISDIÇÃO</h5>
            <p>
              Estes Termos e Condições de Uso e a relação decorrente das ações aqui compreendidas,
              assim como qualquer disputa que surja em virtude disto será regulada exclusivamente
              pela legislação brasileira. Fica eleito o Foro do domicílio da ABR TELECOM, para
              dirimir eventuais questões relacionadas ou oriundas da utilização do serviço regulado
              por este Termo.
            </p>

            <h5>DISPOSIÇÕES GERAIS</h5>
            <p>
              Estes Termos e Condições de Uso não geram nenhum contrato entre as Prestadoras dos
              Serviços de Telecomunicações participantes, ABR TELECOM e o USUÁRIO. Este instrumento
              não gera qualquer direito ou obrigação perante terceiros, exceto conforme
              expressamente previsto aqui. Os casos omissos neste instrumento serão resolvidos de
              conformidade com as disposições legais aplicáveis.
            </p>
            <!--
            <h5>
              DIREITO À PRIVACIDADE
            </h5>
            <p>
              Ao utilizar o serviço via website www.cadastropre.com.br, o USUÁRIO toma ciência que
              todas as informações serão tratadas em conformidade com estes Termos e Condições
              de Uso, com a respectiva Política de Privacidade do website, bem como de acordo com
              a legislação vigente.
            </p>
            <p>
              A informação fornecida pelo USUÁRIO será tratada exclusivamente para a finalidade
              informada no presente Termo e na Política de Privacidade, sendo observada
              integralmente a legislação vigente sobre a proteção de dados, sobretudo, mas não
              exclusivamente, a Lei 13.709/ 2018, quando aplicável.
            </p>
            <h5>
              LIMITAÇÃO DE RESPONSABILIDADE
            </h5>
            <p>
              A ABR TELECOM é responsável pelo funcionamento e operação do serviço do website
              www.cadastropre.com.br em nível de capacidade e cuidado.
            </p>
            <h5>
              PROPRIEDADE INTELECTUAL
            </h5>
            <p>
              O website www.cadastropre.com.br, suas páginas, as telas que apresentem as páginas,
              as informações e os materiais neles contidos e composição, salvo se indicado de outra
              forma, são de propriedade da ABR TELECOM. Todos os direitos são protegidos por
              direitos autorais, marcas registradas, legislação específica e demais leis do Brasil.
              Você concorda em observar todas as leis aplicáveis, assim como quaisquer notificações
              ou restrições adicionais contidas no website.
            </p>
            <p>
              Nada contido nesse site deve ser entendido como uma forma de conceder licença ou
              direito de usar qualquer marca registrada sem a autorização prévia da ABR TELECOM.
            </p>
            <h5>
              ALTERAÇÕES
            </h5>
            <p>
              Está reservado à ABR TELECOM o direito de modificar ou alterar estes Termos e
              Condições de Uso a qualquer momento. As alterações destes Termos e Condições serão
              disponibilizadas no website www.cadastropre.com.br e serão válidas a partir da data
              de sua publicação.
            </p>
            <h5>
              JURISDIÇÃO
            </h5>
            <p>
              Estes Termos e Condições de Uso e a relação decorrente das ações aqui compreendidas,
              assim como qualquer disputa que surja em virtude disto será regulada exclusivamente
              pela legislação brasileira. Fica eleito o Foro do domicílio da ABR TELECOM, para
              dirimir eventuais questões relacionadas ou oriundas da utilização do serviço regulado
              por este Termo.
            </p>
            <h5>
              DISPOSIÇÕES GERAIS
            </h5>
            <p>
              Estes Termos e Condições de Uso não geram nenhum contrato entre as Prestadoras dos
              Serviços de Telecomunicações participantes, ABR TELECOM e o USUÁRIO. Este instrumento
              não gera qualquer direito ou obrigação perante terceiros, exceto conforme
              expressamente previsto aqui. Os casos omissos neste instrumento serão resolvidos de
              conformidade com as disposições legais aplicáveis.
            </p>
            <h5>POLÍTICA DE PRIVACIDADE - WEBSITE WWW.CADASTROPRE.COM.BR</h5>
            <p>
              Esta Política de Privacidade descreve as práticas adotadas em relação as informações
              coletadas dos USUÁRIOS por meio do site www.cadastropre.com.br, mantido pelas
              Prestadoras de Serviços de Telecomunicações participantes e operado e controlado pela
              ABR TELECOM, de forma centralizada. O site www.cadastropre.com.br tem por objetivo
              fornecer ao USUÁRIO a possibilidade de realizar uma consulta, de forma rápida e
              segura, sobre quais Prestadoras de Serviços de Telecomunicações Participantes possuem
              linhas ativas no CPF consultado.
            </p>
            <p>
              Em todos os aspectos essa Política de Privacidade está respaldada na legislação
              brasileira em vigor aplicável à proteção de dados.
            </p>
            <h5>
              1. DA ACEITAÇÃO
            </h5>
            <p>
              1.1. Ao utilizar o conteúdo do site www.cadastropre.com.br, o
              <strong>USUÁRIO</strong> expressa sua aceitação aos termos aqui constantes, para
              todos os fins de direito. Assim, declara que fez leitura atenta e completa da
              presente Política de Privacidade.
            </p>
            <h5>
              2. QUAIS SÃO AS INFORMAÇÕES OBTIDAS
            </h5>
            <p>
              2.1. Informações coletadas a partir da navegação do USUÁRIO no site
              www.cadastropre.com.br:
              <ul>
                <li>CPF</li>
                <li>logs</li>
                <li>cookies</li>
              </ul>
            </p>
            <p>
              2.2. Todas as tecnologias utilizadas pelo site www.cadastropre.com.br respeitarão
              sempre a legislação vigente e os termos desta Política de Privacidade.
            </p>
            <h5>
              3. COMO SERÃO UTILIZADAS AS INFORMAÇÕES
            </h5>
            <p>
              3.1. Podemos utilizar as informações coletadas no site www.cadastropre.com.br para
              as seguintes finalidades, todas relacionadas com o objetivo da criação do site
              em apreço:
              <ul>
                <li>aperfeiçoamento dos serviços no site e diagnóstico de problemas;</li>
                <li>elaboração de análises estatísticas, de segurança e combate à fraude;</li>
                <li>cumprimento de ordem judicial ou requerimento administrativo.</li>
              </ul>
            </p>
            <p>
              3.2. O dado coletado e as atividades registradas também poderão ser compartilhados
              com autoridades judiciais, administrativas ou governamentais competentes, sempre que
              houver requerimento, requisição ou ordem judicial.
            </p>
            <p>
              3.3. A base de dados formada por meio da coleta das informações no site
              www.cadastropre.com.br é de responsabilidade da ABR TELECOM, dentro do limite de suas
              atribuições, sendo que seu uso, acesso e compartilhamento, quando necessários, serão
              realizados dentro dos propósitos dos serviços prestados, obedecendo ao disposto na
              presente <strong>Política de Privacidade.</strong>
            </p>
            <p>
              3.4. As informações coletadas serão acessadas somente por profissionais devidamente
              autorizados respeitando os princípios de proporcionalidade, necessidade, finalidade,
              segurança e adequação para os objetivos, além do compromisso de confidencialidade e
              preservação da privacidade nos termos desta <strong>Política de Privacidade</strong>.
            </p>
            <h5>
              4. ARMAZENAMENTO DOS DADOS E REGISTROS
            </h5>
            <p>
              4.1. As informações coletadas serão armazenadas pelo período necessário para o
              alcance das finalidades pretendidas, em ambiente seguro e controlado, observados
              os requisitos de segurança dispostos na legislação brasileira.
            </p>
            <p>
              4.1.1. No entanto, por motivo de lei, regulamentação ou determinação judicial, os
              dados poderão ser mantidos por período superior, findo o qual, serão excluídos com
              uso de métodos de descarte seguro.
            </p>
            <p>
              4.2 As informações coletadas serão armazenadas preferencialmente em servidores no
              Brasil, em formato que favoreça o exercício do direito de acesso. Eventualmente os
              dados podem ser processados em outros países conforme a operação do provedor de
              infraestrutura demandar, sempre respeitando a legislação brasileira.
            </p>
            <h5>
              5. DISPOSIÇÕES GERAIS
            </h5>
            <p>
              5.1. O teor desta Política de Privacidade poderá ser alterado a qualquer momento,
              conforme a finalidade ou necessidade, assim como para adequação e conformidade legal
              de disposição de lei ou norma que tenha força jurídica equivalente, mantendo a
              ABR Telecom as versões atualizadas, a serem verificadas por nosso USUÁRIO todas as
              vezes que acessar o site www.cadastropre.com.br.
            </p>
            <h5>
              6. LEI APLICÁVEL E JURISDIÇÃO
            </h5>
            <p>
              6.1. Esta <strong>Política de Privacidade</strong> e a relação decorrente das ações
              aqui compreendidas, assim como qualquer disputa que surja em virtude disto será
              regulada exclusivamente pela legislação brasileira.
            </p>
            <p>
              6.2. Fica eleito o Foro da Circunscrição Judiciária de Brasília-DF, para dirimir
              qualquer questão envolvendo o presente documento, renunciando as partes a qualquer
              outro, por mais privilegiado que seja ou venha a ser.
            </p> -->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success"
              style="background-color: #6087bc; border-color: #6087bc"
              data-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermosCondicoes",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table,
th,
td {
  padding: 0.5rem;
  border: 1px solid #0055aa;
}

th {
  background-color: #0055aa;
  text-align: center;
  color: #fff;
  font-size: 1.2rem;
}

td {
  color: #000;
  font-size: 1.2rem;
}

p {
  color: gray;
  text-align: justify;
  margin: 2rem;
  font-size: 1.2rem;
}

/* h5 {
    text-align: start;
    margin-left: 2rem;
    margin-right: 2rem;
} */
</style>
