<template>
  <div id="app">
    <Layout></Layout>
  </div>
</template>

<script>
import Layout from './components/Layout.vue';

export default {
  name: 'app',
  components: {
    Layout,
  },
};
</script>

<style lang="scss">
  @import './styles/main.scss';
</style>
