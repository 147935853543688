<template>
  <section id="consultation">
    <span class="title">CONSULTA</span>
    <div class="body-card">
      <div class="row justify-center">
        <div class="col-xs-12 col-sm-10 col-md-8 col-lg-6">
          <span class="leading">
            Digite seu CPF abaixo para consultar se possui <br/>
            linha pré-paga ativa nas Prestadoras participantes:
          </span>
        </div>
      </div>

      <form>
        <div class="row input-row justify-center">
          <div class="col-xs-10 col-sm-9 col-md-8 col-lg-6 input-col">
            <input
              type="text"
              name="cpf"
              id="cpf"
              placeholder="Digite aqui seu CPF..."
              class="form-control input"
              v-model="cpf_v"
              required
              v-mask="'###.###.###-##'"
            />
          </div>
        </div>
        <div class="row terms-row justify-center" v-if="!showResult()">
          <div class="col-xs-10 col-sm-9 col-md-7 col-lg-5 justify-center">
            <!-- <input
              type="checkbox"
              class="1acc84"
              id="termos"
              v-model="termos"
              required
            /> -->
            <label class="form-check-label" for="termos">
              &nbsp; É de responsabilidade do <strong>USUÁRIO</strong> ler
              e certificar-se de haver entendido todas as disposições nos
              <a data-toggle="modal" class="termos" href="#modalTermos">
                termos e condições de uso
              </a>
            </label>
          </div>
        </div>

        <div class="row captcha-row justify-center" v-if="!showResult()">
          <div class="col-xs-9 col-md-8 col-lg-6 justify-center">
            <vue-hcaptcha
              ref="hcaptcha"
              @expired="onHCaptchaExpired"
              @verify="onHCaptchaVerified"
              size="200"
              sitekey="116be0d5-7c40-4e61-92df-b28363f9faf8"
            ></vue-hcaptcha>
          </div>
        </div>

        <div class="row submit-row justify-center" v-if="!showResult()">
          <div class="col-xs-7 col-sm-4 col-md-4 col-lg-3 justify-center">
            <button
              class="btn submit-button btn-primary btn-block"
              type="submit"
              @click="consultaPre($event)"
            >
              <span v-if="!isLoading">CONSULTAR</span>
              <i
                v-if="isLoading"
                class="fa fa-circle-o-notch fa-spin"
                style="font-size: 1.4rem"
              ></i>
            </button>
          </div>
        </div>

        <div v-if="results.rows && results.rows.length > 0">
          <div class="row justify-center tip-row">
            <p
              class="tip col-xs-9 col-sm-10 col-md-8 col-lg-6"
              style="margin: 1.2rem"
            >
              Relação de Prestadora(s) com linha(s) pré-paga(s) ativa(s) para o
              CPF, conforme avaliação em todo o território nacional:
            </p>
          </div>
          <div class="row justify-center table-row" style="margin: 1rem 0;">
            <div class="table-scroll col-xs-12 col-sm-10 col-md-8 col-lg-6">
              <table>
                <thead>
                  <th>Prestadora(s)</th>
                  <th v-if="results.showContato">Contato</th>
                  <th>UF(s)</th>
                </thead>
                <tbody>
                  <tr v-for="item in results.rows" :key="item.operadora">
                    <td v-if="results.showContato">
                      <a :href="item.contatoUrl" target="_blank">{{
                        item.operadora
                      }}</a>
                    </td>
                    <td v-else>{{ item.operadora }}</td>
                    <td v-if="results.showContato">{{ item.contatoFone }}</td>
                    <td v-if="results.showQtde">{{ item.ufs_com_qtds }}</td>
                    <td v-else>{{ item.ufs }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <div
            class="row justify-center tip"
            style="margin: 1.2rem"
            v-if="results && results.rows && results.rows.length === 0"
          >
            <div class="col-xs-9 col-sm-10 col-md-8 col-lg-6">
              <p class="info">
                Em todo território nacional, o CPF consultado não possui linha
                pré-paga ativa nas Prestadoras participantes.
              </p>
            </div>
          </div>
          <div class="row justify-center submit-row" style="margin: 1.2rem" v-if="showResult()">
            <div class="col-xs-8 col-sm-5 col-md-4 col-lg-3 justify-center">
              <button
                class="btn btn-success submit-button btn-block"
                type="button"
                @click="novaConsulta()"
              >
                Nova Consulta
              </button>
            </div>
          </div>
        </div>

        <div id="alertError" class="row justify-center row-hint" v-if="errors">
          <div class="col-xs-9 col-md-8 col-sm-9 col-lg-6 justify-center">
            <div class="alert alert-danger" data-html="true" role="alert">
              <button
                type="button"
                class="close"
                @click="errors = null"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <span v-if="errors">
                {{ errors }}
              </span>
            </div>
          </div>
        </div>

        <div class="row row-hint justify-center">
          <div class="col-xs-11 col-sm-11 col-md-8 col-lg-6 justify-center">
            <span class="hint-attention">ATENÇÃO: </span>
            <span class="hint-text">
              As informações são atualizadas em até 30 dias
            </span>
          </div>
        </div>
      </form>
    </div>
    <TermosCondicoes></TermosCondicoes>
  </section>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import settings from "@/settings";
import TermosCondicoes from "./TermosCondicoes.vue";

export default {
  name: "Consulta",
  props: {
    msg: String,
  },
  components: { VueHcaptcha, TermosCondicoes },
  data() {
    return {
      cpf_v: null,
      termos: true,
      captcha: null,
      results: [],
      errors: null,
      isLoading: false,
    };
  },
  methods: {
    validarCPF(inputCpf) {
      const cpf = inputCpf.replace(/[^\d]+/g, "");

      if (cpf === "") return false;

      // Elimina CPFs invalidos conhecidos
      if (
        cpf.length !== 11
        || cpf === "00000000000"
        || cpf === "11111111111"
        || cpf === "22222222222"
        || cpf === "33333333333"
        || cpf === "44444444444"
        || cpf === "55555555555"
        || cpf === "66666666666"
        || cpf === "77777777777"
        || cpf === "88888888888"
        || cpf === "99999999999"
      ) {
        return false;
      }
      // Valida 1o digito
      let add = 0;
      for (let i = 0; i < 9; i += 1) {
        add += parseInt(cpf.charAt(i), 10) * (10 - i);
      }
      let rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) rev = 0;
      if (rev !== parseInt(cpf.charAt(9), 10)) return false;
      // Valida 2o digito
      add = 0;
      for (let i = 0; i < 10; i += 1) {
        add += parseInt(cpf.charAt(i), 10) * (11 - i);
      }
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) {
        rev = 0;
      }
      if (rev !== parseInt(cpf.charAt(10), 10)) return false;
      return true;
    },
    onHCaptchaExpired() {
      this.$refs.hcaptcha.reset();
    },
    onHCaptchaVerified(hcaptchaToken) {
      this.captcha = hcaptchaToken;
    },
    handleErrors(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    },
    novaConsulta() {
      this.results = [];
      this.cpf_v = null;
      this.isLoading = false;
    },
    showResult() {
      if (this.results && this.results.rows) {
        return true;
      }
      return false;
    },
    consultaPre(event) {
      event.preventDefault();

      this.errors = null;
      this.results = [];

      if (!this.cpf_v || !this.validarCPF(this.cpf_v)) {
        this.errors = "Informe um CPF válido!";
        this.$refs.hcaptcha.reset();
        return;
      }

      if (!this.captcha) {
        this.errors = "Por favor informe o hCAPTCHA. "
          + "Caso não apareça a opção do hCAPTCHA recarregue a página.";
        return;
      }

      // if (!this.termos) {
      //   this.errors = "Para prosseguir é necessário aceitar os termos e condições!";
      //   this.$refs.hcaptcha.reset();
      //   return;
      // }

      const cpf = this.cpf_v.replace(/[^\d]+/g, "");
      const body = JSON.stringify({
        cpf,
        captcha: this.captcha,
        termos: this.termos,
      });
      this.isLoading = true;
      const apiUrl = settings.API_URL + "/prepagos";
      fetch(apiUrl, {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body,
      })
        .then(this.handleErrors)
        .then(res => res.json())
        .then((data) => {
          if (!data.success) {
            if (data.invalidHcaptcha) {
              this.errors = "hCaptcha inválido, para prosseguir é necessário confirmar o hCaptcha novamente!";
            }

            if (data.invalidCpf) {
              this.errors = "Informe um CPF válido!";
            }

            // if (data.invalidTermos) {
            //   this.errors = "Para prosseguir é necessário aceitar os termos e condições!";
            // }

            if (data.invalidRateLimit) {
              this.errors = "Você alcançou o limite de consultas diárias para o IP de origem."
                + " Tente novamente amanhã";
            }
          } else {
            this.errors = null;
            this.results = data;
          }
        })
        .catch(() => {
          this.errors = "Não foi possível acessar o servidor, "
            + "verifique sua conexão com a internet";
        })
        .finally(() => {
          this.captcha = null;
          this.$refs.hcaptcha.reset();
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
#consultation {
  background-color: #6087bc;
  padding: 5rem 16vw;
  text-align: center;

  .title {
    color: #fff;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .body-card {
    margin-top: 35px;
    background-color: #fff;
    border-radius: 25px;
    padding: 32px 32px;

    .leading {
      font-size: 1.1rem;
      color: #5e5e5e;
    }

    form {
      .input-row {
        margin-top: 25px;

        .input-col {
          padding: 0;

          input {
            border: 2px solid #6087bc;
            border-radius: 25px;
            height: 50px;
            padding: 6px 18px;
            font-size: 1.1rem;
          }
        }
      }

      .captcha-row {
        margin-top: 15px;
      }

      .terms-row {
        margin-top: 15px;

        input {
          margin: 6px 0 0;
        }

        .form-check-label {
          font-weight: 400;
          margin-left: 6px;
          color: #5e5e5e;
          font-size: 1.1rem;
        }
      }

      .submit-row {
        margin-top: 15px;

        .submit-button {
          background-color: #6087bc;
          border-radius: 25px;
          letter-spacing: 1px;
          padding: 12px 16px;
          font-size: 1.1rem;
          border: 0;
        }
      }

      .row-hint {
        margin-top: 30px;

        .hint-attention {
          color: #6087bc;
          font-weight: 500;
          margin-right: 4px;
        }

        .hint-text {
          color: #5e5e5e;
        }
      }

      .table-row {
        .table-scroll {
          padding: 20px 0px 20px 10px;
          border: 2px solid #6087bc;
          border-radius: 25px;
          table {
            width: 100%;
            table-layout: fixed;
            color: #5e5e5e;

            thead {
              letter-spacing: 1px;

              th {
                font-weight: 400;
                text-transform: uppercase;
                font-size: .9rem;
                padding-bottom: 15px;
                text-align: center;
              }
            }
          }

          @media screen and (max-width: 360px) {
            padding: 20px 0px 20px 6px;

            table {
              thead {
                th {
                  font-size: .8rem;
                }
              }

              tbody {
                tr {
                  font-size: .9rem;
                }
              }
            }
          }
        }
      }

      .tip-row {
        p {
          color: #5e5e5e;
          font-size: 1.1rem;
        }
      }
    }
  }

  .justify-center {
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    padding: 2rem 6vw;

    .title {
      font-size: 1.8rem;
    }

    .body-card {
      padding: 20px;
      margin-top: 20px;

      .leading {
        font-size: 0.9rem;
      }

      .input-row {
        input {
          border-radius: 20px;
        }
      }

      .terms-row {
        input {
          margin: 4px 0 0;
        }

        label {
          font-size: 0.95rem;
        }
      }

      .captcha-row {
        transform: scale(0.8);
      }

      .submit-row {
        button {
          border-radius: 20px;
          font-size: 1rem;
        }
      }

      .row-hint {
        div {
          font-size: 0.8rem;
        }
      }
    }
  }
}
</style>
