<template>
  <nav
    class="navbar navbar-default navbar-fixed-top"
    v-bind:class="{ isFixed: this.isFixedNavBar }"
    role="navigation"
  >
    <div class="container">
      <div class="navbar-header">
        <a href="#" class="navbar-brand">
          <img class="logo-desktop" src="../assets/images/logo_consulta.png" />
          <img
            class="logo-mobile"
            data-toggle="collapse"
            data-target=".navbar-collapse"
            src="../assets/images/logo_consulta_branco.png"
          />
        </a>

        <div class="toggle-container">
          <button
            class="navbar-toggle"
            data-toggle="collapse"
            data-target=".navbar-collapse"
          >
            <span class="icon icon-bar"></span>
            <span class="icon icon-bar"></span>
            <span class="icon icon-bar"></span>
          </button>
        </div>
      </div>
      <div class="collapse navbar-collapse">
        <ul class="nav navbar-nav navbar-right text-uppercase">
          <li>
            <a class="nav-item" href="#consultation">CONSULTAR</a>
            <a
              class="mobile-nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              href="#consultation"
              >CONSULTAR</a
            >
          </li>
          <li>
            <a class="nav-item" href="#faq">F.A.Q.</a>
            <a
              class="mobile-nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              href="#faq"
              >F.A.Q.</a
            >
          </li>
          <li>
            <a class="nav-item" href="#contact">CONTATO <br />PRESTADORAS</a>
            <a
              class="mobile-nav-item"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              href="#contact"
              >CONTATO <br />PRESTADORAS</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navigation",
  props: {
    isFixedNavBar: Boolean,
  },
};
</script>

<style lang="scss">
.navbar-default {
  border: 0;
  background: transparent;

  .container {
    padding: 40px 0;
  }

  .navbar-brand {
    padding: 0;
    display: flex;
    height: 100%;

    img {
      align-self: center;
    }

    .logo-mobile {
      display: none;
    }
  }

  .mobile-nav-item {
    display: none;
  }

  @media screen and (max-width: 767px) {
    .container {
      padding: 0 15px;
    }
    .navbar-header {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      background-color: #6087bc;

      .navbar-brand {
        img {
          height: 44px;
          margin: 15px 0;
          align-self: center;
        }

        .logo-mobile {
          display: block;
        }

        .logo-desktop {
          display: none;
        }
      }

      .toggle-container {
        display: flex;
        justify-content: flex-end;

        .navbar-toggle {
          align-self: center;
          border: 0;
          background-color: #6087bc !important;

          .icon-bar {
            background-color: #fff;
          }
        }
      }
    }

    .navbar-collapse {
      background-color: #6087bc;
      border: 0px;
      .navbar-right {
        li {
          text-align: center;

          a {
            color: #fff;
            font-size: 1.1rem;
            letter-spacing: 1px;
            font-weight: 400;
          }
        }

        .nav-item {
          display: none;
        }

        .mobile-nav-item {
          display: block;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    position: absolute !important;
    margin-top: 60px;
    height: 80px;

    .container {
      height: 100%;
      padding: 0;

      .navbar-header {
        height: 100%;

        .navbar-brand {
          height: 100%;

          img {
            height: 100%;
          }
        }
      }

      .navbar-collapse {
        height: 100% !important;

        .navbar-right {
          height: 100%;
          display: flex;
          column-gap: 7px;
          align-items: center;

          li {
            //   background-color: #6087bc;
            border-radius: 30px;
            width: 165px;
            min-height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            a {
              width: 100%;
              color: #fff;
              font-size: 1.2rem;
              letter-spacing: 1px;
              font-weight: 500;
              padding: 0;
            }
          }

          li:hover {
            background-color: #6087bc;
            cursor: pointer;
          }
        }
      }
    }

    &.isFixed {
      position: fixed !important;
      margin: 0;
      background-color: #424242;
      height: 90px;
      min-height: 0;
      padding: 15px 25px;
      animation-name: slideDown-md;
      animation-duration: 1s;

      .container {
        padding: 0;
      }

      .navbar-brand {
        img {
          height: 70px;
          margin: 0;
        }
      }

      .navbar-collapse {
        .navbar-right {
          li {
            width: 165px;
            min-height: 47px;
            margin: 20px 0;

            a {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1023px) {
    position: absolute !important;
    margin-top: 80px;
    height: 110px;

    .container {
      height: 100%;
      padding-left: 15px;

      .navbar-header {
        height: 100%;

        .navbar-brand {
          height: 100%;

          img {
            height: 100%;
          }
        }
      }

      .navbar-collapse {
        height: 100% !important;

        .navbar-right {
          height: 100%;
          display: flex;
          column-gap: 10px;
          align-items: center;

          li {
            //   background-color: #6087bc;
            border-radius: 30px;
            width: 190px;
            min-height: 54px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            a {
              color: #fff;
              font-size: 1.2rem;
              letter-spacing: 1px;
              font-weight: 500;
              padding: 0;
            }
          }

          li:hover {
            background-color: #6087bc;
            cursor: pointer;
          }
        }
      }
    }

    &.isFixed {
      position: fixed !important;
      margin: 0;
      background-color: #424242;
      height: 100px;
      min-height: 0;
      padding: 15px 25px;
      animation-name: slideDown-bg;
      animation-duration: 1s;

      .container {
        padding: 0;
      }

      .navbar-brand {
        img {
          height: 70px;
          margin: 12px;
        }
      }

      .navbar-collapse {
        .navbar-right {
          li {
            width: 170px;
            min-height: 50px;
            margin: 20px 0;

            a {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1920px) {
    .container {
      .navbar-collapse {
        .navbar-right {
          li {
            width: 210px;
            min-height: 64px;
          }
        }
      }
    }
  }
}

@keyframes slideDown-md {
  from {
    position: fixed;
    height: 0;
    padding: 0;
  }
  to {
    height: 90px;
    min-height: 0;
    padding: 15px 25px;
  }
}

@keyframes slideDown-bg {
  from {
    position: fixed;
    height: 0;
    padding: 0;
  }
  to {
    height: 100px;
    min-height: 0;
    padding: 15px 25px;
  }
}
</style>
