<template>
  <section id="faq">
    <span class="title">F.A.Q.</span>

    <div class="row top2" v-if="isLoading">
      <div class="col-xs-2 col-md-2 col-md-offset-5 col-xs-offset-5">
        <div class="loader"></div>
      </div>
    </div>

    <div class="questions" v-if="results.rows && results.rows.length > 0">
      <div
        class="question"
        v-for="(item, index) in results.rows"
        :key="item.DC_TITULO"
      >
        <a
          class="collapse-panel"
          role="button"
          data-toggle="collapse"
          :href="'#collapse-' + index"
          aria-expanded="false"
          :aria-controls="'collapse-' + index"
        >
          <span>{{ item.DC_TITULO }}</span>
          <i class="fa fa-caret-left"></i>
        </a>
        <div
          class="collapse"
          :id="'collapse-' + index"
          v-html="item.DC_CONTEUDO"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable operator-linebreak */
import settings from "@/settings";

export default {
  name: "Faq",
  props: {
    msg: String,
  },
  data() {
    return {
      results: [],
      errors: null,
      isLoading: false,
    };
  },
  created() {
    this.isLoading = true;
    const apiUrl = settings.API_URL + "/faq";
    fetch(apiUrl, { headers: { "Content-type": "application/json" } })
      // eslint-disable-next-line arrow-parens
      .then((res) => res.json())
      .then((data) => {
        console.log("faq data: ", data);

        if (!data.success) {
          this.errors = "Falha ao consultar Perguntas Frequentes.";
        } else {
          const results = data;
          const linkMap = [
            { string: '"Consulta"', link: "#consultation" },
            { string: '"Contato"', link: "#contact" },
          ];

          results.rows = data.rows.map((row) => {
            linkMap.forEach((map) => {
              if (row.DC_CONTEUDO.includes(map.string)) {
                const link = map.string.link(map.link);

                // eslint-disable-next-line no-param-reassign
                row.DC_CONTEUDO = row.DC_CONTEUDO.replace(map.string, link);
              }
            });

            return row;
          });

          this.errors = null;
          this.results = results;
        }
      })
      .catch((error) => {
        this.errors =
          "Não foi possível acessar o servidor, " +
          "verifique sua conexão com a internet";
        console.error(error);
      })
      .finally((result) => {
        console.log(result);
        this.isLoading = false;
      });
  },
};
</script>

<style lang="scss">
#faq {
  padding: 4rem 16vw;
  text-align: center;

  .title {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .questions {
    margin: 1.5rem 0 0 0;

    .question {
      padding: 14px;
      border: 2px solid #6087bc;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      text-align: left;
      margin-bottom: 18px;

      a {
        font-size: 1.1rem;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          max-width: 95%;
        }

        i {
          font-size: 30px;
          float: right;
          margin-top: -2px;
          margin-right: 5px;
        }

        &[aria-expanded="false"] {
          color: #5e5e5e;
          font-weight: 400;
        }

        &[aria-expanded="true"] {
          color: #6087bc;
          font-weight: 500;

          i {
            transform: rotate(-90deg);
          }
        }
      }

      .collapse {
        padding: 6px 2px;
        color: #5e5e5e;

        a {
          display: contents;
        }
      }

      .collapsing {
        a {
          display: contents;
        }
      }
    }

    .question:last-child {
      margin-bottom: 0px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 2rem 6vw;

    .title {
      font-size: 1.8rem;
    }

    .questions {
      .question {
        padding: 7px;
        margin-bottom: 14px;
      }
    }
  }
}
</style>
